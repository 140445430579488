<template>
  <div class="sealChange">
    <header>
      <div class="header-box">
        <!-- <svg-icon class="logo" icon-class="zxlogo" /> -->
        <div>变更</div>
      </div>
    </header>
    <div class="sealChangeMain " v-loading="loading">
      <div class="step-content" v-if="stepActive == -1">
        <div class="changeUkey">
          <div class="changeMatter">
            <div class="item-title">变更注意事项</div>
            <p>（1）确认是否下载并安装【豸信CA数字证书助手驱动】。</p>
            <p>（2）将需要变更的Ukey插入电脑USB接口，Ukey密码验证成功后进行变更。（若遇到控件拦截，需要全部运行控件）</p>
            <p>（3）推荐使用【IE】、【Chrome】、【Edge】、【360】浏览器。 </p>
            <p><span class="el-icon-warning"></span> 变更请插入一个Ukey操作，以免造成数据冲突，造成变更失败。</p>
          </div>
          <template>
            <div class="item-title">变更信息</div>
            <div class="item-box">
              <div class="info-box old">
                <div class="tt">原证书信息</div>
                <div class="info" v-if="info.oldCert.organization">
                  <div><span>所属机构/O：</span><span>{{info.oldCert.organization}}</span></div>
                  <div><span>所属部门/OU：</span><span>{{info.oldCert.organizationalUnit}}</span></div>
                  <template>
                    <div><span>证书名称/CN：</span><span>{{info.oldCert.commonName}}</span></div>
                    <div><span>证件类型：</span><span>{{IdentType(info.oldCert.orgIdentType)}}</span></div>
                    <div><span>证件号码：</span><span>{{info.oldCert.orgIdentNo}}</span></div>
                  </template>
                  <div><span>法人姓名：</span><span>{{info.oldCert.personName}}</span></div>
                  <div><span>法人身份证号：</span><span>{{info.oldCert.personIdentNo}}</span></div>

                </div>
                <div class="info" v-if="!info.oldCert.organization">
                  <div><span>证书名称/CN：</span><span>{{info.oldCert.commonName}}</span></div>
                  <div><span>持有人姓名：</span><span>{{info.oldCert.personName}}</span></div>
                  <div><span>持有人证件类型：</span><span>{{IdentType(info.oldCert.personIdentType)}}</span></div>
                  <div><span>持有人身份证号：</span><span>{{info.oldCert.personIdentNo}}</span></div>
                </div>
                <div class="sealImg" v-if="info.isSealForm && info.oldCert.patternFilePath.length > 0"><img v-for="key in info.oldCert.patternFilePath" :key="key" :src="key"></div>
              </div>
              <div class="arrows">
                变更为
                <img src="@/assets/icons/service/arrows.png" alt="">
              </div>
              <div class="info-box">
                <div class="tt">变更后的证书信息</div>
                <div class="info" v-if="info.oldCert.organization">
                  <div><span>所属机构/O：</span><span>{{info.newCert.organization}}</span></div>
                  <div><span>所属部门/OU：</span><span>{{info.newCert.organizationalUnit}}</span></div>
                  <template v-if="info.newCert.certType!==2">
                    <div><span>证书名称/CN：</span><span>{{info.newCert.commonName}}</span></div>
                    <div><span>证件类型：</span><span>{{IdentType(info.newCert.orgIdentType)}}</span></div>
                    <div><span>证件号码：</span><span>{{info.newCert.orgIdentNo}}</span></div>
                  </template>
                  <div><span>法人姓名：</span><span>{{info.newCert.personName}}</span></div>
                  <div><span>法人身份证号：</span><span>{{info.newCert.personIdentNo}}</span></div>
                </div>
                <div class="info" v-if="!info.newCert.organization">
                  <div><span>证书名称/CN：</span><span>{{info.newCert.commonName}}</span></div>
                  <div><span>持有人姓名：</span><span>{{info.newCert.personName}}</span></div>
                  <div><span>持有人证件类型：</span><span>{{IdentType(info.newCert.personIdentType)}}</span></div>
                  <div><span>持有人身份证号：</span><span>{{info.newCert.personIdentNo}}</span></div>
                </div>
                <div class="sealImg" v-if="info.isSealForm && info.newCert.patternFilePath.length > 0"><img v-for="key in info.newCert.patternFilePath" :key="key" :src="key"></div>
              </div>
            </div>
            <el-button @click="connectUkey" type="primary">立即变更</el-button>
          </template>
        </div>
      </div>
      <div class="changeing" v-if="stepActive == 0">
        <div class="icon">
          <img class="img" src="@/assets/icons/service/loading-icon.png" alt="">
        </div>
        <div class="tt">证书变更中</div>
        <div class="hint">提示：请不要关闭浏览器，不要拔出Ukey，在此界面等待更新完毕，以免造成证书更新失败</div>
      </div>
      <div class="changeing" v-if="stepActive == 1">
        <div class="icon">
          <img src="@/assets/icons/service/check-success-icon.png" alt="">
        </div>
        <div class="tt">变更成功</div>
      </div>
      <div class="changeing" v-if="stepActive ==2">
        <div class="icon">
          <img src="@/assets/icons/service/check-fail-icon.png" alt="">
        </div>
        <div class="tt">变更失败，请重新申请</div>
      </div>
    </div>
    <UkeyLogin v-model="ukeyLoginShow" @logined="ukeyLogined" :Ukey="Ukey" :certSn="certSn" />
  </div>

</template>

<script>
import { Ukey } from '@/views/onlineSealRenewal/ukey'
import UkeyLogin from '@/views/onlineSealRenewal/ukeyLogin.vue'
import { IdentType } from '@/utils/typeMatch'
export default {
  data() {
    return {
      loading: false,
      ukeyLoginShow: false,
      Ukey: null,
      stepActive: -1,
      paramurl: '',
      IdentType,
      transId: '',
      transCode: '',
      pkcs10: '',
      certSn: '',
      info: {
        newCert: {
          organization: '',
          organizationalUnit: '',
          commonName: '',
          orgIdentType: '',
          orgIdentNo: '',
          personName: '',
          personIdentNo: '',
          patternFilePath: []
        },
        oldCert: {
          certSn: '',
          organization: '',
          organizationalUnit: '',
          commonName: '',
          orgIdentType: '',
          orgIdentNo: '',
          personName: '',
          personIdentNo: '',
          patternFilePath: []
        }
      }
    }
  },
  components: {
    UkeyLogin
  },
  created() {
    this.Ukey = new Ukey(this)
    this.getCheckURL()
  },
  methods: {
    ukeyLogined() {
      this.getCertDn()
    },
    getCheckURL() {
      if (this.$route.query.param) {
        this.paramurl = this.$route.query.param
      } else {
        this.$message.error('未获取到证书更新参数')
      }
      this.$api
        .onlineCheckCertUpdatePage({
          param: this.paramurl
        })
        .then(res => {
          if (res.code === '0') {
            console.log(res)
            this.transId = res.data.transId
            this.transCode = res.data.makeCertCode
            this.$api
              .onlineCheckCertUpdateInfo({
                transId: res.data.transId,
                makeCertCode: res.data.makeCertCode
              })
              .then(res => {
                if (res.code === '0') {
                  this.info = res.data
                } else {
                  this.$message({
                    message: res.message,
                    type: 'error'
                  })
                }
              })
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
    },
    connectUkey() {
      this.loading = true
      this.Ukey.connect()
        .then(certSn => {
          console.log(certSn)
          this.certSn = certSn.toLowerCase()
          if (
            certSn !== this.info.oldCert.certSn.toLowerCase() &&
            certSn !== this.info.newCert.certSn.toLowerCase()
          ) {
            this.$alert(
              '当前插入Ukey非办理变更业务所使用的，请更换Ukey',
              '提示',
              {
                confirmButtonText: '我知道了'
              }
            )
            this.loading = false
          } else {
            this.ukeyLoginShow = true
          }
        })
        .catch(err => {
          if (err.message)
            this.$message({
              message: err.message,
              type: 'error'
            })
        })
    },
    getPostponeCert() {
      this.$api
        .onlineCheckCertUpdate({
          transId: this.transId,
          pkcs10: this.pkcs10,
          transCode: this.transCode,
          certSn: this.info.oldCert.certSn
        })
        .then(res => {
          this.certData = res.data
          this.Ukey.writeCert(this.certData, 1)
            .then(res => {
              if (res && this.info.isSealForm) {
                return this.Ukey.readUkeySeal(this.certData.sealDTO.length)
              } else if (res && !this.info.isSealForm) {
                return this.Ukey.checkUkey()
              } else {
                this.stepActive = 2
              }
            })
            .then(res => {
              console.log(res)
              if (res.code == -1) {
                this.stepActive = 2
              } else {
                let item = btoa(JSON.stringify(res))
                this.getCheckCertFinish(item)
                // this.stepActive = 1
              }
            })
            .catch(err => {
              this.$msgbox({
                title: '提示',
                message: `<div><p class="el-icon-error" style="color: #FC7163;font-size: 14px;"> 数字证书写入Ukey失败，请拔掉Ukey，再重新插入Ukey进行写入证书。</p></div>`,
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定重新写入',
                confirmButtonClass: 'elCheckSuccess',
                showClose: false
              }).then(() => {
                this.loading = true
                this.Ukey.hasUkey()
                  .then(() => {
                    this.ukeyLoginShow = true
                  })
                  .catch(err => {
                    this.loading = false
                    console.log(err)
                  })
              })
              this.stepActive = 2
              console.log(err)
            })
        })
        .catch(() => {
          this.loading = false
          this.stepActive = 2
        })
    },
    getCheckCertFinish(item) {
      this.$api
        .onlineCheckCertFinish({
          transId: this.transId,
          certBase64: item
        })
        .then(() => {
          this.stepActive = 1
        })
        .catch(() => {
          this.stepActive = 2
        })
    },
    getCertDn() {
      this.stepActive = 0
      this.loading = false
      this.$api
        .onlineCertDn({
          transId: this.transId
        })
        .then(res => {
          this.Ukey.getPkcs10(res.msg, this.info.oldCert.keyLength)
            .then(res => {
              this.pkcs10 = res.data.CSR
              this.getPostponeCert()
            })
            .catch(err => {
              this.stepActive = 2
              this.$message({
                message: err.message,
                type: 'error'
              })
            })
        })
        .catch(() => {
          this.stepActive = 2
        })
    }
  }
}
</script>
<style lang="scss">
.elCheckSuccess span {
  color: #fff !important;
}
.elCheckSuccess:hover span {
  color: #fff !important;
}
.elCheckSuccess span:hover {
  color: #fff !important;
}
</style>
<style lang="scss" scoped>
@keyframes loading {
  // 0% {
  //   transform: rotate(0deg);
  // }
  100% {
    transform: rotate(360deg);
  }
}
.sealChange {
  background: #f5f8fb;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  // bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
header {
  text-align: center;
  border-bottom: 1px #eaeaea solid;
  background-color: #fff;
  display: block;
  width: 100%;
  margin-bottom: 16px;
  .header-box {
    text-align: center;
    font-size: 16px;
    color: #333333;
    height: 60px;
    line-height: 60px;
    width: 100%;
    font-weight: bold;
  }
}
.sealChangeMain {
  flex: 1;
  background: #fff;
  width: 1300px;
  margin: auto;
}
.changeing {
  padding-top: 226px;
  text-align: center;
  height: 100px;
  .icon {
    width: 52px;
    height: 52px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px #e4e5e6;
    margin: 0 auto;
    border-radius: 50%;
    padding: 5px;
    img {
      width: 42px;
      height: 42px;
    }
    .img {
      animation: loading 0.6s linear infinite;
    }
  }
  .tt {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    margin: 24px auto;
  }
  .hint {
    width: 828px;
    background: #f7faff;
    border-radius: 2px;
    line-height: 94px;
    font-size: 16px;
    color: #3a6afe;
    margin: 0 auto;
  }
}
.item-title {
  // border-left: 3px $primaryColor solid;
  font-size: 14px;
  font-weight: bold;
  color: #303b50;
  line-height: 19px;
  position: relative;
  border-bottom: 1px #dee2e8 solid;
  padding: 8px 12px 16px;
  margin-bottom: 24px;
  .hint {
    color: #ff443e;
  }
  &::before {
    position: absolute;
    width: 3px;
    height: 16px;
    content: '';
    background: $primaryColor;
    top: 8px;
    left: 0;
  }
}
.changeUkey {
  padding: 16px 24px 32px;
  .item-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .arrows {
      color: #303b50;
      img {
        width: 43px;
        height: 14px;
        margin-left: 8px;
      }
    }
    .info-box {
      &.old {
        .info {
          background: #f7faff;
        }
      }
      .tt {
        font-size: 18px;
        font-weight: bold;
        color: #303b50;
        line-height: 19px;
        margin-bottom: 16px;
      }
    }
    .info {
      width: 548px;
      background: #f7fffc;
      padding: 24px;
      & > div {
        display: flex;
        line-height: 19px;
        font-size: 14px;
        font-weight: bold;
        color: #303b50;
        margin-bottom: 32px;
        &:last-child {
          margin: 0;
        }
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        & > span:first-child {
          font-weight: normal;
          flex-shrink: 0;
          color: #6c737b;
        }
      }
      .sealImg {
        img {
          width: 98px;
          margin: 0 6px;
          height: auto;
          display: inline-block;
        }
      }
    }
  }
  .el-button {
    width: 182px;
    display: block;
    margin: 0 auto;
    margin-top: 32px;
  }
}
.changeMatter {
  p {
    font-size: 14px;
    color: #303b50;
    margin-bottom: 32px;
  }
  p:last-child {
    color: #ffb500;
  }
}
</style>